<template>
  <v-dialog :value="dialog" max-width="300px" persistent @keydown.esc="close">
    <v-card>
      <v-card-title>
        <span>Add a new HDD</span>
      </v-card-title>
      <v-divider />
      <ValidationObserver v-slot="{ invalid, passes }" disabled>
        <v-card-text>
          <v-form style="width: 100%">
            <ERow no-gutters>
              <ECol cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Serial Number"
                  rules="required"
                >
                  <v-text-field
                    v-model="defaultItem.serialNumber"
                    dense
                    outlined
                    :error-messages="errors"
                    label="Serial Number"
                    placeholder="Serial Number"
                    @keydown.space.prevent
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Size"
                  rules="required"
                >
                  <v-select
                    ref="size"
                    v-model="defaultItem.size"
                    :items="hddSizes"
                    label="Size (TB)"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Status"
                  rules="required"
                >
                  <v-select
                    ref="hddStatus"
                    v-model="defaultItem.hddStatus"
                    :items="hddStatuses"
                    label="Status"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Type"
                  rules="required"
                >
                  <v-select
                    ref="type"
                    v-model="defaultItem.type"
                    :items="hddTypes"
                    label="Type"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Format"
                  rules="required"
                >
                  <v-select
                    ref="format"
                    v-model="defaultItem.format"
                    label="Format"
                    :items="hddFormats"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Format"
                  rules="required"
                >
                  <v-select
                    ref="location"
                    v-model="defaultItem.locationId"
                    label="Location"
                    :items="hddLocations"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12">
                <v-text-field
                  v-model="defaultItem.shelfBlock"
                  dense
                  outlined
                  label="Shelf Block"
                />
              </ECol>
            </ERow>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> Cancel </v-btn>
          <v-btn
            color="primary"
            text
            :loading="isLoading"
            :disabled="invalid"
            @click="passes(saveHdd)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import {
  HDD_FORMATS,
  HDD_SIZES,
  HDD_TYPES,
  HDD_STATUSES,
  LOCATIONS,
} from "@/components/constants.js"

export default {
  props: {
    hdd: {
      type: Object,
      default: () => {},
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      defaultItem: {
        size: "",
        type: "",
        format: "",
        locationId: "",
        serialNumber: "",
        hddStatus: "Blank",
        shelfBlock: "",
      },
      hddSizes: HDD_SIZES,
      hddStatuses: HDD_STATUSES,
      hddTypes: HDD_TYPES,
      hddFormats: HDD_FORMATS,
      hddLocations: LOCATIONS,
      isLoading: false,
    }
  },
  computed: {
    ...mapStores(useReportStore),
  },
  watch: {
    dialog(value) {
      if (value && this.hdd?.location) {
        const locationId = this.hddLocations.filter(
          (location) => location.name == this.hdd.location
        )[0]?.value
        this.defaultItem = { ...this.hdd, locationId: locationId }
      }
    },
  },
  methods: {
    close() {
      this.$emit("close-dialog")
      this.defaultItem = {
        size: "",
        type: "",
        format: "",
        locationId: "",
        serialNumber: "",
        hddStatus: "Blank",
        shelfBlock: "",
      }
    },
    saveHdd() {
      this.reportStore.loading = true
      this.isLoading = true
      this.$nextTick(async () => {
        try {
          if (this.hdd?.id) {
            await AdminApi.hdds.updateHdd(this.hdd.id, this.defaultItem)
          } else {
            await AdminApi.hdds.saveHdd(this.defaultItem)
          }

          this.close()
          this.reportStore.searchFilters = {}
          this.reportStore.loading = false
          this.isLoading = false
          this.$notifications.success("HDD updated successfully.")
        } catch (error) {
          this.$notifications.error({ text: "Updating Hdd failed", error })
        }
      })
    },
  },
}
</script>
