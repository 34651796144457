<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="hdds"
          sort-by="created_at"
          item-key="id"
          :headers="headers"
          :sort-desc="true"
          :provider="provider"
          :copy-to-clipboard="false"
          show-expand
          single-expand
          :filter-fields="filterFields"
        >
          <template #actions-right>
            <HddsDialog
              :dialog="isHddDialogOpen"
              :hdd="selectedHdd"
              @close-dialog="closeHddDialog"
            />
            <v-btn
              color="primary"
              class="mr-1"
              small
              @click="isHddDialogOpen = true"
            >
              Add HDD
            </v-btn>
          </template>
          <template #item.edit="{ item }">
            <ERow justify="center" align="center">
              <v-btn
                icon
                color="primary"
                target="_blank"
                @click="editHdd(item)"
              >
                <v-icon color="primary"> fas fa-pencil </v-icon>
              </v-btn>
            </ERow>
          </template>
          <template #item.delete="{ item }">
            <ERow justify="center" align="center">
              <v-btn
                icon
                color="primary"
                target="_blank"
                @click="openDeleteDialog(item)"
              >
                <v-icon color="error"> fas fa-trash </v-icon>
              </v-btn>
            </ERow>
          </template>

          <template #item.data-table-expand="{ isExpanded, expand }">
            <ERow justify="center" align="center" class="px-2">
              <v-icon
                v-if="!isExpanded"
                x-small
                color="primary"
                @click="expand(true)"
              >
                fa-solid fa-chevron-down
              </v-icon>
              <v-icon
                v-if="isExpanded"
                role="button"
                x-small
                color="primary"
                @click="expand(false)"
              >
                fa-solid fa-chevron-up
              </v-icon>
            </ERow>
          </template>
          <template #expanded-item="{ item }">
            <td :colspan="12" class="pl-0 pr-0">
              <HddFootages :item="item" />
            </td>
          </template>
          <template #item.serialNumber="{ item }">
            {{ item.serialNumber }}
            <v-btn
              :style="{ minWidth: 'unset' }"
              color="primary"
              class="px-1 ma-0"
              small
              text
              @click="copyField(item.serialNumber)"
            >
              <v-icon x-small>far fa-copy</v-icon>
            </v-btn>
          </template>
          <template #item.createdAt="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>

          <template #item.location="{ item }">
            <v-chip
              :color="locationsColors[item.location] ?? 'grey'"
              label
              class="ma-1"
              small
              text-color="white"
            >
              {{ item.location }}
            </v-chip>
          </template>
          <template #item.hddStatus="{ item }">
            <p
              :class="`ma-1 ${statusColors[item.hddStatus] ?? 'grey'}--text`"
              small
            >
              {{ item.hddStatus ?? "N/A" }}
            </p>
          </template>
        </ReportTable>
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
import HDDsHeader from "@/components/hdds/hddsTableHeaders"
import HddFootages from "@/components/hdds/HddFootages"
import HddsDialog from "@/components/hdds/HddsDialog"
import filterFields from "@/components/hdds/hddsSearchFilters"
import ReportTable from "@/components/ReportTable"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { WarehouseLocation, HddState } from "@evercam/shared/types/hdd"

export default {
  components: {
    HddFootages,
    ReportTable,
    HddsDialog,
  },
  data: () => {
    return {
      editedSim: {},
      headers: HDDsHeader,
      isHddDialogOpen: false,
      filterFields: {},
      locationsColors: {
        [WarehouseLocation.IEWarehouse]: "#32CD32",
        [WarehouseLocation.Deployed]: "#FFD700",
        [WarehouseLocation.CustomerStorage]: "#808080",
        [WarehouseLocation.USWarehouse]: "#fa5f5f",
        [WarehouseLocation.AUWarehouse]: "#FFA500",
        [WarehouseLocation.UKWarehouse]: "#1E90FF",
      },
      statusColors: {
        [HddState.Blank]: "grey",
        [HddState.InUse]: "green",
        [HddState.ColdStorage]: "blue",
      },
      selectedHdd: {},
    }
  },
  head() {
    return {
      title: "Admin - HDDs",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useReportStore, useCameraDialogStore),
    provider() {
      return AdminApi.hdds.getAllHdds
    },
  },
  async created() {
    this.filterFields = filterFields(this)
  },
  methods: {
    async deleteHDD(hdd) {
      try {
        await AdminApi.hdds.deleteHdd(hdd.id)
        this.reportStore.items = this.reportStore.items.filter(
          (item) => item.id !== hdd.id
        )

        this.$notifications.success("HDD deleted successfully.")
      } catch (error) {
        this.$notifications.error({ text: "Delete Hdd failed", error })
      }
    },
    copyField(field) {
      navigator.clipboard.writeText(field)
      this.$notifications.success("Copied to clipboard")
    },
    async openDeleteDialog(item) {
      if (
        await this.$confirmDialog.open({
          title: "Delete HDD",
          message: `Are you sure you want to delete the HDD <span class='font-weight-bold'>${item.serialNumber}</span>? <br> This action cannot be undone!`,
        })
      ) {
        this.deleteHDD(item)
      }
    },
    closeHddDialog() {
      this.isHddDialogOpen = false
      this.selectedHdd = {}
    },
    editHdd(hdd) {
      this.selectedHdd = hdd
      this.isHddDialogOpen = true
    },
  },
}
</script>
