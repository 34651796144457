import {
  HDD_FORMATS,
  HDD_SIZES,
  HDD_TYPES,
  HDD_STATUSES,
  LOCATIONS_SEARCH_FILTER,
} from "@/components/constants.js"

export default () => {
  return {
    id: {
      component: "TextFieldSearchFilter",
      name: "id",
    },
    location: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...LOCATIONS_SEARCH_FILTER],
        value: "",
      },
    },
    serialNumber: {
      component: "TextFieldSearchFilter",
      name: "serialNumber",
    },
    shelfBlock: {
      component: "TextFieldSearchFilter",
      name: "ShelfBlock",
    },
    format: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...HDD_FORMATS],
        value: "",
      },
    },
    size: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...HDD_SIZES],
        value: "",
      },
    },
    type: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...HDD_TYPES],
        value: "",
      },
    },
    hddStatus: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...HDD_STATUSES],
        value: "",
      },
    },
  }
}
